import { useGlobalUserStore } from "../../../state/userState"
import { sharedStyles } from "../../../styles/sharedStyles"

export default function Home(){

    const username = useGlobalUserStore(state => state.email);

    return <div style={{ ...styles.wrapper, ...sharedStyles.pageWrapper }}>
        <h1 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            Home
        </h1>
        <p>
            Angemeldeter Nutzer: {username}
        </p>
    </div>
}

const styles = {
    wrapper:{
        flexGrow: 1,
        padding: 20
    }
}