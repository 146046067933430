import { useEffect, useState } from "react";
import { Button } from "../../../lib/shadcn/ui/button";
import { AppLayout, useGlobalAppLayoutStore } from "../../../state/appLayoutState";
import { sharedStyles } from "../../../styles/sharedStyles"
import useRemoteScanEvents from "../../../service/remote/useRemoteScanEvents";
import { Item } from "../../../model/remote/Item";
import { useGlobalItemStore } from "../../../state/itemsState";
import { Card } from "../../../lib/shadcn/ui/card";
import { CircleAlert, ClipboardCheck, ClipboardX, MapPin, WifiOff } from "lucide-react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "../../../lib/shadcn/ui/alert";

export const displayModeSelectionLoader = async ({ request }:{request:Request}):Promise<AppLayout> => {
    const url = new URL(request.url);
    const layout = url.searchParams.get("layout");
    return layout === "content-only"?"content-only":"normal";
}

export default function DisplayModeSelection() {

    const fetchInterval = 8000;
    const availableCutoff = 17000;

    const [appLayout, setAppLayout] = useGlobalAppLayoutStore(state => [state.appLayout, state.setAppLayout]);
    const { fetchAllMostRecentItemScans } = useRemoteScanEvents();
    const globalItems = useGlobalItemStore(store => store.items);

    const requestLayout = useLoaderData() as AppLayout;
    const { pathname, search } = useLocation();
    const navigate = useNavigate()

    const [mostRecentTimestamps, setMostRecentTimestamps] = useState<Array<{
        itemId: number,
        timestamp: Date
    }>>([]);
    const [mostRecentFetch, setMostRecentFetch] = useState(0);
    const [fetchError, setFetchError] = useState(false);

    const toggleLayout = () => {
        const urlParams = new URLSearchParams(search);
        if (appLayout === "normal") {
            urlParams.set('layout', 'content-only');
            navigate(`?${urlParams.toString()}`);
        }else{
            urlParams.set('layout', 'normal');
            navigate(`?${urlParams.toString()}`);
        }
    }

    const itemTimestamp = (item: Item) => {
        return mostRecentTimestamps.filter((filterItem) => filterItem.itemId === item.id)[0]?.timestamp.getTime() || 0;
    }
    const itemsWithinTolerance = () => {
        return globalItems.filter(item => {
            return Date.now() - itemTimestamp(item) < availableCutoff
        })
    }

    const availableItems = itemsWithinTolerance();

    /**
     * checks whether the most recent fetch is within a
     * specific timeframe to check if the data is up to date
    */
    const mostRecentFetchValid = () => {
        return mostRecentFetch + availableCutoff > Date.now();
    }

    /**
     * Tries to convert the first 3 chars of a string to a number
     * and then sorts an arry by that
     */
    const customSort = (itemArray:Array<Item>) => itemArray.sort((a, b) => {
        let checkLen = 3;
        //extract first chars and convert to number
        let firstCharsA = a.itemName.slice(0,checkLen);
        let firstCharsB = b.itemName.slice(0,checkLen);
        let parsedCharsA = parseInt(firstCharsA);
        let parsedCharsB = parseInt(firstCharsB);
        //no leading numbers
        if(isNaN(parsedCharsA) && isNaN(parsedCharsB)){
            return 0;
        }
        //only leading number in one
        if(isNaN(parsedCharsA) && !isNaN(parsedCharsB)){
            return 1;
        }
        if(!isNaN(parsedCharsA) && isNaN(parsedCharsB)){
            return -1;
        }
        //both are numbers
        return parsedCharsA - parsedCharsB;
    })

    /**
     * Toggle view depending on path
     */
    useEffect(() => {
        setAppLayout(requestLayout);
    }, [requestLayout]);
    /**
     * Initialize items
     */
    useEffect(() => {
        setMostRecentTimestamps(globalItems.map(item => ({
            itemId: item.id,
            timestamp: new Date(0)
        })));
    }, [globalItems]);

    useEffect(() => {
        const fetchAllItemScans = () => {
            fetchAllMostRecentItemScans(fetchInterval * 2).then(
                recentScans => {
                    setMostRecentTimestamps(oldTimestamps => oldTimestamps.map(
                        oldItem => {
                            oldItem.timestamp = recentScans.filter(item => item.itemId === oldItem.itemId)[0]?.timestamp || new Date(0);
                            return oldItem;
                        }
                    ))
                }
            ).then(()=>setFetchError(false))
            .catch(error =>{
                setFetchError(true)
            })
            .finally(() => {
                setMostRecentFetch(Date.now())
            })
        }
        fetchAllItemScans();
        let interval = setInterval(() => {
            fetchAllItemScans();
        }, fetchInterval)
        return () => {
            clearInterval(interval);
        }
    }, [globalItems])

    const renderItem = ({ item, index, available }: {
        item: Item,
        index: number,
        available: boolean
    }) => {

        const timeD = Date.now() - itemTimestamp(item);
        return <div style={{ width: "50%", overflow: "hidden" }} key={index}>
            <Card style={{
                margin: 2,
                padding:3,
                //borderColor: "#757575",
                borderColor: available ? "green" : "",
                background: available ? "green" : "" }} className="flex justify-between">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{
                            fontSize: 14,
                            color: available ? "white":"black",
                            fontWeight: "bold",
                            whiteSpace: "nowrap"
                        }}>{item.itemName}</div>
                    </div>
                </div>
            </Card>
        </div>
    }

    let addedStyle = {
        padding: (appLayout === "content-only") ? 0 : 20
    }

    return <div style={{ ...styles.wrapper, ...sharedStyles.pageWrapper, ...addedStyle }}>
        {
            appLayout !== "content-only" && <h1 style={{marginBottom:20}} className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">Display Mode</h1>
        }
        <div style={styles.locationHeader}>
            <MapPin size={24} style={{ marginRight: 6 }} />
            Lagerraum
        </div>
        {
            fetchError && <Alert style={styles.alert} variant={"destructive"}>
                <WifiOff size={18} />
                <AlertTitle>Verbindungsproblem</AlertTitle>
                <AlertDescription>
                    Dies kann zB an der Wlan Verbindung liegen
                </AlertDescription>
            </Alert>
        }
        {
            globalItems.length === 0 &&
            <Alert style={styles.alert} variant={"default"}>
                <CircleAlert size={18} />
                <AlertTitle>Keine Gegenstände gefunden</AlertTitle>
                <AlertDescription>
                    Es wurden keine eingetragenen Gegenstände gefunden
                </AlertDescription>
            </Alert>
        }
        {
            globalItems.length !== 0 && !mostRecentFetchValid() && <div>Loading...</div>
        }
        {
            globalItems.length !== 0 && mostRecentFetchValid() &&
            <div style={styles.columnWrapper}>
                <div style={{ ...styles.availableColumn, ...styles.categoryColumn }}>
                    <div style={styles.columnHeader}>
                        <ClipboardCheck strokeWidth={1.5} size={20} style={{ marginRight: 10 }} />
                        Verfügbar
                    </div>
                    {
                        mostRecentFetchValid() && availableItems.length !== 0 &&
                        //@ts-ignore
                        <div style={styles.columnContent}>
                            {
                                customSort(availableItems).map((item, index) => renderItem({ item, index, available: true }))
                            }
                        </div>
                    }
                </div>
                <div style={{ ...styles.notAvailableColumn, ...styles.categoryColumn }}>
                    <div style={styles.columnHeader}>
                        <ClipboardX strokeWidth={1.5} size={20} style={{ marginRight: 10 }} />
                        Nicht Verfügbar
                    </div>
                    {
                        mostRecentFetchValid() && availableItems.length < globalItems.length &&
                        //@ts-ignore
                        <div style={styles.columnContent}>
                            {
                                customSort(
                                    globalItems
                                    .filter(item => availableItems.indexOf(item) === -1)
                                )
                                    .map((item, index) => renderItem({ item, index, available: false }))
                            }
                        </div>
                    }
                </div>
            </div>
        }
        <Button variant={"ghost"} style={styles.toggleButton} onClick={toggleLayout}>Toggle Layout</Button>
    </div>
}

const styles = {
    wrapper: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    locationHeader: {
        fontSize: 24,
        display: "flex",
        alignItems: "center",
        paddingTop: 4,
        paddingLeft: 8
    },
    alert:{
        margin:10,
        width:"calc(100% - 20px)"
    },
    toggleButton: {
        margin: 10
    },
    columnWrapper: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        flexGrow: 1,
        
    },
    columnHeader: {
        fontSize: 16,
        padding: 2,
        borderBottom: "1px solid rgb(231, 231, 231)",
        marginBottom: 3,
        display: "flex",
        alignItems: "center"
    },
    categoryColumn: {
        padding: 4
    },
    columnContent:{
        display: "flex",
        flexWrap: "wrap"
    },
    availableColumn: {
    },
    notAvailableColumn: {
    }
}
