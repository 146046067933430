import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { NO_ACCOUNT_ID, useGlobalUserStore } from "../../../state/userState";
import { useEffect } from "react";


export default function MainRoot() {

    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const globalAccountId = useGlobalUserStore((state) => state.id);

    //navigate to login if no user is defined
    useEffect(() => {
        if (globalAccountId === NO_ACCOUNT_ID) {
            console.log("No account - redirecting to login");
            //do not pass target path on logout
            let urlEncodedTargetPath = encodeURIComponent(
                (pathname !== "/logout")?pathname:"/"
            );
            //append all target path data to redirect after login
            navigate(`/login?tp=${urlEncodedTargetPath}&params=${encodeURIComponent(search)}`);
        }
    }, [globalAccountId]);

    return (
        <div style={styles.appWrapper}>
            <Navbar />
            <div style={styles.content}>
                <Outlet />
            </div>
        </div>
    );
}

const styles = {
    appWrapper: {
        display: "flex",
        height: "100vh",
        width: "100vw"
    },
    content: {
        display: "flex",
        flexGrow: 1,
        overflow: "auto"
    }
}