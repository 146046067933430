import * as React from "react"

import { Button } from "../../../lib/shadcn/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../lib/shadcn/ui/card"
import { Input } from "../../../lib/shadcn/ui/input"
import { Label } from "../../../lib/shadcn/ui/label"
import { NO_ACCOUNT_ID, useGlobalUserStore } from "../../../state/userState"
import { useEffect, useState } from "react"
import { Authentication } from "../../../model/remote/Authentication"
import UserService from "../../../service/remote/UserService"
import { Alert, AlertDescription, AlertTitle } from "../../../lib/shadcn/ui/alert"
import { useLocation, useNavigate } from "react-router-dom"

export function Login() {

    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const [globalLogIn, accountId] = useGlobalUserStore((state) => ([state.logIn, state.id]));
    const saveUserToStorage = useGlobalUserStore((state) => state.saveToStorage);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loginError, setLoginError] = useState(false);

    const logIn = async () => {
        setLoginError(false);
        let auth: Authentication;
        try {
            auth = await UserService.authenticate(email, password);
        } catch (error) {
            setLoginError(true);
            console.log(error);
            return;
        }
        let serverUser;
        try {
            serverUser = await UserService.get(auth);
        } catch (err) {
            setLoginError(true);
            console.error(err);
            return;
        }
        if (!serverUser) {
            setLoginError(true);
            console.log("Could not fetch user");
            return;
        }
        console.log("Login User", serverUser);
        globalLogIn(serverUser, auth);
        await saveUserToStorage();
    }

    useEffect(()=>{
        if(accountId !== NO_ACCOUNT_ID){
            const urlParams = new URLSearchParams(search);
            navigate(`${urlParams.get("tp")}${urlParams.get("params")}` || "/");
        }
    }, [accountId]);

    return (
        <div style={styles.cardWrapper}>
            <Card className="w-[350px]" style={styles.card}>
                <CardHeader>
                    <CardTitle>Login to your DOORA service</CardTitle>
                    <CardDescription>Please enter your credentials</CardDescription>
                </CardHeader>
                <CardContent>
                    <form>
                        <div className="grid w-full items-center gap-4">
                            {
                                loginError &&
                                <Alert variant={"destructive"}>
                                    <AlertTitle>Error</AlertTitle>
                                    <AlertDescription>
                                        You could not be logged in
                                    </AlertDescription>
                                </Alert>
                            }
                            <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Email</Label>
                                <Input
                                    placeholder="email"
                                    value={email}
                                    onInput={e => {
                                        //@ts-ignore
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="flex flex-col space-y-1.5">
                                <Label htmlFor="name">Password</Label>
                                <Input
                                    placeholder="Password"
                                    type="password"
                                    onInput={e => {
                                        //@ts-ignore
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </CardContent>
                <CardFooter className="flex justify-between" style={styles.buttonRow}>
                    <Button onClick={logIn}>Login</Button>
                </CardFooter>
            </Card>
        </div>
    )
}

const styles: any = {
    cardWrapper: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    card: {

    },
    buttonRow: {
        display: "flex",
        justifyContent: "flex-end"
    }
}
